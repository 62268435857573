import { Link, graphql } from 'gatsby';
import React from 'react';

import urns from '../../assets/images/cremation-urns.jpg';
import Guarantee from '../../components/guarantee';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Service from '../../components/service';

const Services = ({ data }) => {
  const services = data.allMarkdownRemark.edges;

  return (
    <Layout
      headerClass='relative bg-white'
      hasHero={false}
      heroSize='20rem'
      heroImage={urns}
    >
      <SEO
        title='Services | Cremation, Burial, Funeral'
        description='Affordable cremation, immediate burial, traditional funerals. City of Oaks Cremation is a full-service funeral home in Raleigh serving all of NC and beyond.'
      />

      <div className='flex flex-col mt-10 md:mt-16'>
        <h1 className='text-lg mt-2 mb-6'>
          City of Oaks Funeral Home and Cremation provides exceptional service
          for direct cremation, immediate burial, or traditional funerals at an
          affordable price.
        </h1>
        <p className='mb-2 text-4xl text-gray-800 self-center'>Our Services</p>

        <div className='flex flex-wrap justify-center items-stretch -mx-2'>
          {/* <div className="flex flex-wrap"> */}
          {services
            .sort((a, b) =>
              a.node.frontmatter.weight > b.node.frontmatter.weight
                ? 1
                : b.node.frontmatter.weight > a.node.frontmatter.weight
                ? -1
                : 0
            )
            .map(({ node }) => (
              <Service
                title={node.frontmatter.title}
                slug={node.frontmatter.slug}
                url={node.frontmatter.path}
                key={node.frontmatter.path}
                icon={node.frontmatter.icon}
                price={node.frontmatter.price}
                blurb={node.frontmatter.blurb}
              />
            ))}
        </div>
      </div>
      <Guarantee />
    </Layout>
  );
};

export const query = graphql`
  query ServicesQuery {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/content/services/" } }
      sort: { fields: [frontmatter___weight], order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            path
            icon
            price
            blurb
            weight
          }
          excerpt
        }
      }
    }
  }
`;

export default Services;
